import { useState, useRef, useContext, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useWindowSize } from 'usehooks-ts';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/solid';

import ABNService from '../../services/ABNService';
import AuthService from '../../services/AuthService';
import { GlobalContext } from '../../context/GlobalContext';
import UiUtils from '../../utils/UiUtils';
import * as GeneralConstants from '../../constants/GeneralConstants';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';

export default function SignUpPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const { context, setContextValues } = useContext(GlobalContext);

    const [signedUp, setSignedUp] = useState(context.signedUp ? context.signedUp : false);

    const [abnStatus, setAbnStatus] = useState('');
    const [hideAbnButton, setHideAbnButton] = useState(false);

    const processingClass = 'font-vg-regular text-black text-base text-center mt-6';
    const errorClass = 'font-vg-regular text-red text-base text-center';
    const doneClass = 'font-vg-regular text-black text-base text-center';
    const [hideSubmit, setHideSubmit] = useState(false);
    const [statusClass, setStatusClass] = useState(doneClass);
    const [statusText, setStatusText] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [isTrial, setIsTrial] = useState(false);

    const captchaRef = useRef(null);

    const validationSchema = Yup.object().shape({
        trialAccount: Yup.boolean(),
        abn: Yup.string().when("trialAccount", {
            is: false,
            then: schema => schema.required('Please enter a valid ABN'),
            otherwise: schema => schema.optional()
        }),
        organisationName: Yup.string().when("trialAccount", {
            is: false,
            then: schema => schema.required('Use ABN search to find valid organisation name'),
            otherwise: schema => schema.optional()
        }),
        email: Yup.string()
            .required('Please enter an email address')
            .email('Please enter a valid email address'),
        password: Yup.string().required('Please enter a password'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords do not match!"),
        agreeToTerms: Yup.boolean().oneOf([true], 'Please indicate you have read & agree to the Terms of Service and Privacy Policy')
    });
    
    const formOptions = { 
        resolver: yupResolver(validationSchema),
        defaultValues: {
            abn: "",
            organisationName: "",
            prefTradingName: "",
            email: "",
            firstName: "",
            lastName: "",
            jobTitle: "",
            password: "",
            confirmPassword: "",
            captcha: "",
            trialAccount: false,
            agreeToTerms: false,
            agreeToComms: false
        } 
    };
    const { 
        control, 
        handleSubmit, 
        setValue,
        getValues
    } = useForm(formOptions);

    function doABNSearch(e) {
        e.preventDefault();
        setHideAbnButton(true);
        setAbnStatus('Searching...');
        const abn = getValues("abn");
        ABNService.checkAbn(abn, (error, data) => {
            setHideAbnButton(false);
            if (error) {
                alert(error);
                setAbnStatus('');
            } else {
                if (data.EntityName !== '') {
                    setValue("organisationName", data.EntityName);
                    setAbnStatus('');
                } else if (data.BusinessName.length > 0) {
                    setValue("organisationName", data.BusinessName[0]);
                    setAbnStatus('');
                } else {
                    if (data.Message !== '') {
                        setAbnStatus(data.Message);
                    } else {
                        setAbnStatus('No results returned from ABN search');
                    }
                }
            }
        });
    }

    const onPasswordIconClick = () => {
        setShowPassword(!showPassword);
    }

    const onConfirmPasswordIconClick = () => {
        setShowConfirmPassword(!showPassword);
    }

    const onSubmit = (data) => {
        setHideSubmit(true);
        setStatusClass(processingClass);
        setStatusText("Signing Up...");
        const details = {
            abn: data.abn,
            organisationName: data.organisationName,
            prefTradingName: data.prefTradingName,
            firstName: data.firstName,
            lastName: data.lastName,
            jobTitle: data.jobTitle,
            email: data.email,
            password: data.password,
            captcha: captchaRef.current.getValue(),
            trialAccount: data.trialAccount,
            agreeToTerms: data.agreeToTerms,
            agreeToComms: data.agreeToComms
        }
        AuthService.startSignUp(details)
        .then((_) => {
            setHideSubmit(false);
            setStatusClass(doneClass);
            setStatusText("");
            setContextValues([{ key: 'signedUp', value: true }]);
            setSignedUp(true);
        })
        .catch((error => {
            console.log('error', error);
            setHideSubmit(false);
            setStatusClass(errorClass);
            setStatusText(error.response && error.response.data ? error.response.data.message || error.message : error.message);
        }));
    };

    /* Matomo Tracking Code */

    useEffect(() => {
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push(['setDocumentTitle', 'Sign Up']);
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);
        
    return (
        <>
        {isDesktop && (
            <div className="box-border w-full h-full px-16 py-6 flex flex-col items-stretch gap-10">
                <Header title="Sign up"/>
                {!signedUp && (
                <div className="grid grid-cols-12 gap-6 max-w-content-narrow mx-auto">
                    <div className="col-span-5 col-start-1 flex flex-col items-stretch py-12">
                        <div className="flex flex-col items-start gap-4">
                            <h2 className="font-vg-medium text-5xl text-grey05 leading-110">Register your<br/>social enterprise</h2>
                            <p className="font-vg-book text-base leading-130 text-grey06">
                                Create your free Seedkit account to access to tools and resources to better understand, measure and report on your 
                                organisation's operations and impacts.
                                <br/><br/>
                                If you only want to try out some of the features of Seedkit to determine whether it is right for you, you can create a <span className="font-vg-medium">trial account</span>.
                                With a <span className="font-vg-medium">trial account</span>, you will not be able export data or dashboard, or create new users for your organisation. 
                                <br/><br/>
                                You may convert a <span className="font-vg-medium">trial account</span> to a full account at any time.
                            </p>
                        </div>
                    </div>
                    <form className="col-span-6 col-start-7 flex flex-col items-stretch gap-8 px-8 py-10 rounded-3xl bg-grey03" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-2">
                            <h4 className="font-vg-medium text-3.5xl leading-110 text-black">Please fill out the registration form</h4>
                            <p className="font-vg-book text-base text-grey">An * indicates that entry is required</p>
                        </div> 
                        <div className="flex flex-col items-stretch gap-3">
                            <div className={isTrial ? "hidden" : "relative"}>
                                <Controller
                                    name="abn"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            label="Organisation's ABN" 
                                            variant="default"
                                            showHelp="always"
                                            required={!isTrial}
                                            help={abnStatus ? abnStatus : formState.errors.abn?.message}
                                            {...field}
                                        />
                                    )}
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center justify-center">
                                    <button 
                                        className={hideAbnButton ? "hidden" : "flex items-center justify-center p-3"}
                                        onClick={doABNSearch}
                                    >
                                        <MagnifyingGlassCircleIcon className="h-6 w-6 text-blue"/>
                                    </button>
                                </div>
                            </div>
                            <Controller
                                name="organisationName"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Organisation's name"
                                        variant="disabled"
                                        showHelp="always"
                                        className={isTrial ? "hidden" : ""}
                                        readOnly={true}
                                        required={!isTrial}
                                        help={formState.errors.organisationName?.message ? formState.errors.organisationName?.message : GeneralConstants.ORG_NAME_HELP}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="trialAccount"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Checkbox
                                        ref={ref}
                                        label="I only want to create a trial account"
                                        className={isTrial ? "mb-4" : "my-4"}
                                        labelClass="font-vg-regular text-base text-black"
                                        checked={value}
                                        onChange={(e) => {
                                            setIsTrial(e.target.checked);
                                            onChange(e.target.checked);
                                        }}
                                        tooltip={GeneralConstants.TRIAL_ACCOUNT_TOOLTIP}
                                    />
                                )}
                            />
                            <Controller
                                name="prefTradingName"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label={`Organisation's preferred name or trading name${isTrial ? "" : " (if different)"}`}
                                        variant="default"
                                        showHelp="always"
                                        {...field}
                                    />
                                )}
                            />
                            <div className="grid grid-cols-2 gap-6">
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            label="First name of organisational account holder"
                                            variant="default"
                                            showHelp="always"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            label="Last name of organisational account holder"
                                            variant="default"
                                            showHelp="always"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <Controller
                                name="jobTitle"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Job title of organisational account holder"
                                        variant="default"
                                        showHelp="always"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="email"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Organisation email for account registration"
                                        variant="default"
                                        showHelp="always"
                                        required="true"
                                        help={formState.errors.email?.message}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Password"
                                        type={showPassword ? "text" : "password"} 
                                        icon={showPassword ? "EyeOffIcon" : "EyeIcon"}
                                        variant="default"
                                        showHelp="always"
                                        required="true"
                                        help={formState.errors.password?.message}
                                        onIconClick={onPasswordIconClick}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="confirmPassword"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Confirm password"
                                        type={showConfirmPassword ? "text" : "password"} 
                                        icon={showConfirmPassword ? "EyeOffIcon" : "EyeIcon"}
                                        variant="default"
                                        showHelp="always"
                                        required="true"
                                        help={formState.errors.confirmPassword?.message}
                                        onIconClick={onConfirmPasswordIconClick}
                                        {...field}
                                    />
                                )}
                            />
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY} 
                                ref={captchaRef}
                            />
                            <Controller
                                name="agreeToTerms"
                                control={control}
                                render={({ field: { onChange, value, ref }, formState }) => (
                                    <Checkbox
                                        ref={ref}
                                        variant={formState.errors.agreeToTerms?.message ? "error" : "default"}
                                        label='I have read and agree to the <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>'
                                        labelClass="font-vg-book text-base text-grey"
                                        className="mt-4"
                                        help={formState.errors.agreeToTerms?.message}
                                        checked={value}
                                        onChange={(e) => {
                                            onChange(e.target.checked);
                                        }}
                                        required={true}
                                    />
                                )}
                            />
                            <Controller
                                name="agreeToComms"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Checkbox
                                        ref={ref}
                                        label="I would like to receive communications and news from Seedkit."
                                        labelClass="font-vg-book text-base text-grey"
                                        className="mb-4"
                                        checked={value}
                                        onChange={(e) => {
                                            onChange(e.target.checked);
                                        }}
                                    />
                                )}
                            />
                            <div className="flex flex-col items-stretch gap-3">
                                <Button 
                                    type="submit" 
                                    size="large" 
                                    variant="solid"
                                    label={isTrial ? "Sign Up For Trial Account" : "Sign Up"}
                                    className={hideSubmit ? "hidden" : ""}
                                />
                                <p className={statusClass} aria-live="polite">{statusText}</p>
                            </div>
                        </div>
                    </form>
                </div>
                )}
                {signedUp && (
                <div className="grid grid-cols-12 gap-6 max-w-content-narrow mx-auto">
                    <div className="col-span-6 col-start-4 flex flex-col gap-10 items-start">
                        <h4 className="font-vg-medium text-3.5xl leading-110 text-grey05 py-12">Thank you for signing up to Seedkit</h4>
                        <p className="font-vg-book text-base leading-130 text-grey06">
                            A verification email has been sent to your email address.
                            <br/><br/>
                            Please find the email and follow the instructions in it to begin using your account.
                        </p>
                    </div>
                </div>
                )}
                <Footer/>
            </div>
        )}
        {isMobile && (
            <div className="box-border w-full h-full px-5 py-[34px] flex flex-col items-stretch gap-10">
                <Header title="Sign up"/>
                {!signedUp && (
                <>
                    <div className="flex flex-col items-stretch gap-4">
                        <h2 className="font-vg-medium text-5xl text-grey05 leading-110">Register your<br/>social enterprise</h2>
                        <p className="font-vg-book text-base leading-130 text-grey06">
                        Create your free Seedkit account to access to tools and resources to better understand, measure and report on your 
                                organisation's operations and impacts.
                                <br/><br/>
                                If you only want to try out some of the features of Seedkit to determine whether it is right for you, you can create a <span className="font-vg-medium">trial account</span>.
                                With a <span className="font-vg-medium">trial account</span>, you will not be able export data or dashboard, or create new users for your organisation. 
                                <br/><br/>
                                You may convert a <span className="font-vg-medium">trial account</span> to a full account at any time.
                        </p>
                    </div>
                    <form className="flex flex-col items-stretch gap-8 px-6 py-12 rounded-3xl bg-grey03" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-2">
                            <h4 className="font-vg-medium text-3.5xl leading-110 text-black">Please fill out the registration form</h4>
                            <p className="font-vg-book text-base text-grey">An * indicates that entry is required</p>
                        </div> 
                        <div className="flex flex-col items-stretch gap-3">
                            <div className={isTrial ? "hidden" : "relative"}>
                                <Controller
                                    name="abn"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            label="Organisation's ABN" 
                                            variant="default"
                                            showHelp="always"
                                            required={!isTrial}
                                            help={abnStatus ? abnStatus : formState.errors.abn?.message}
                                            {...field}
                                        />
                                    )}
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center justify-center">
                                    <button 
                                        className={hideAbnButton ? "hidden" : "flex items-center justify-center p-3"}
                                        onClick={doABNSearch}
                                    >
                                        <MagnifyingGlassCircleIcon className="h-6 w-6 text-blue"/>
                                    </button>
                                </div>
                            </div>
                            <Controller
                                name="organisationName"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Organisation's name"
                                        variant="disabled"
                                        showHelp="always"
                                        required={!isTrial}
                                        help={formState.errors.organisationName?.message ? formState.errors.organisationName?.message : GeneralConstants.ORG_NAME_HELP}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="trialAccount"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Checkbox
                                        ref={ref}
                                        label="I only want to create a trial account"
                                        className={isTrial ? "mb-4" : "my-4"}
                                        labelClass="font-vg-medium text-base text-black"
                                        checked={value}
                                        onChange={(e) => {
                                            setIsTrial(e.target.checked);
                                            onChange(e.target.checked);
                                        }}
                                        tooltip={GeneralConstants.TRIAL_ACCOUNT_TOOLTIP}
                                    />
                                )}
                            />
                            <Controller
                                name="prefTradingName"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label={`Organisation's preferred name or trading name${isTrial ? "" : " (if different)"}`}
                                        variant="default"
                                        showHelp="always"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="firstName"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="First name of organisational account holder"
                                        variant="default"
                                        showHelp="always"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Last name of organisational account holder"
                                        variant="default"
                                        showHelp="always"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="jobTitle"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Job title of organisational account holder"
                                        variant="default"
                                        showHelp="always"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="email"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Organisation email for account registration"
                                        variant="default"
                                        showHelp="always"
                                        required="true"
                                        help={formState.errors.email?.message}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Password"
                                        type={showPassword ? "text" : "password"} 
                                        icon={showPassword ? "EyeOffIcon" : "EyeIcon"}
                                        variant="default"
                                        showHelp="always"
                                        required="true"
                                        help={formState.errors.password?.message}
                                        onIconClick={onPasswordIconClick}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="confirmPassword"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        label="Confirm password"
                                        type={showConfirmPassword ? "text" : "password"} 
                                        icon={showConfirmPassword ? "EyeOffIcon" : "EyeIcon"}
                                        variant="default"
                                        showHelp="always"
                                        required="true"
                                        help={formState.errors.confirmPassword?.message}
                                        onIconClick={onConfirmPasswordIconClick}
                                        {...field}
                                    />
                                )}
                            />
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY} 
                                ref={captchaRef}
                            />
                            <Controller
                                name="agreeToTerms"
                                control={control}
                                render={({ field: { onChange, value, ref }, formState }) => (
                                    <Checkbox
                                        ref={ref}
                                        variant={formState.errors.agreeToTerms?.message ? "error" : "default"}
                                        label='I have read and agree to the <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>'
                                        labelClass="font-vg-book text-base text-grey"
                                        className="mt-4"
                                        help={formState.errors.agreeToTerms?.message}
                                        checked={value}
                                        onChange={(e) => {
                                            onChange(e.target.checked);
                                        }}
                                        required={true}
                                    />
                                )}
                            />
                            <Controller
                                name="agreeToComms"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Checkbox
                                        ref={ref}
                                        label="I would like to receive communications and news from Seedkit."
                                        labelClass="font-vg-book text-base text-grey"
                                        className="mb-4"
                                        checked={value}
                                        onChange={(e) => {
                                            onChange(e.target.checked);
                                        }}
                                    />
                                )}
                            />
                            <div className="flex flex-col items-stretch">
                                <Button 
                                    type="submit" 
                                    size="large" 
                                    variant="solid"
                                    label={isTrial ? "Sign Up For Trial Account" : "Sign Up"} 
                                    className={hideSubmit ? "hidden" : ""}
                                />
                                <p className={statusClass} aria-live="polite">{statusText}</p>
                            </div>
                        </div>
                    </form>
                </>
                )}
                {signedUp && (
                <div className="flex flex-col items-stretch gap-4">
                    <h2 className="font-vg-medium text-5xl text-grey05 leading-110">Thank you for signing up to Seedkit</h2>
                    <p className="font-vg-book text-base leading-130 text-grey06">
                        A verification email has been sent to your email address.
                        <br/><br/>
                        Please find the email and follow the instructions in it to begin using your account.
                    </p>
                </div>
                )}
                <Footer/>
            </div>
        )}
        </>
    );
}
