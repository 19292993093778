import { useCallback, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import UiUtils from '../../utils/UiUtils';
import * as GeneralConstants from '../../constants/GeneralConstants';
import * as UiConstants from '../../constants/UiConstants';
import { GlobalContext } from '../../context/GlobalContext';
import ResourceService from '../../services/ResourceService';

import KitSideNav from '../../components/KitSideNav';
import KitTopBar from '../../components/KitTopBar';
import Button from '../../components/Button';
import TagButton from '../../components/TagButton';
import FaqSet from '../../components/FaqSet';
import ResourceBlock from '../../components/ResourceBlock';
import Pagination from '../../components/Pagination';
import SelectDropdown from '../../components/SelectDropdown';
import TrialUpgradeModal from '../../components/TrialUpgradeModal';
import TermsAgreementModal from '../../components/TermsAgreementModal';
import Footer from '../../components/Footer';

export default function KitResourcesPage() {
    const navigate = useNavigate();
    const {context} = useContext(GlobalContext);

    const expandedSideNav = context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true;
    const [navExpanded, setNavExpanded] = useState(expandedSideNav);

    const containerClass = "flex flex-col items-stretch h-screen shrink grow overflow-y-scroll";

    const [resourcesState, setResourcesState] = useState({
        resources: [],
        pageResources: [],
        totalPages: 0,
        page: 0,
        pageLength: 0,
        paginationStart: 0,
        category: UiConstants.RESOURCE_TYPE.All,
        sortSelected: UiConstants.RESOURCE_SORT_OPTIONS[0],
        loaded: false,
        error: "",
        subtitle: ""
    });

    /* Resource Loading Functions */

    const loadResources = useCallback(() => {
        ResourceService.getResources(false, UiConstants.RESOURCE_TYPE.All)
        .then(response => {
            const data = response.data;
            const rsrcs = data.resources;
            const newState = {
                resources: rsrcs,
                pageResources: rsrcs,
                totalPages: Math.ceil(rsrcs.length / UiConstants.RESOURCE_PAGE_SIZE),
                page: 0,
                paginationStart: 0,
                category: UiConstants.RESOURCE_TYPE.All,
                sortSelected: UiConstants.RESOURCE_SORT_OPTIONS[0],
                loaded: true,
                error: "",
                subtitle: ""
            };
            setResourcesState(newState);
        })
        .catch(err => {
            console.log(err);
            setResourcesState({
                resources: [],
                pageResources: [],
                totalPages: 0,
                page: 0,
                paginationStart: 0,
                category: UiConstants.RESOURCE_TYPE.All,
                sortSelected: UiConstants.RESOURCE_SORT_OPTIONS[0],
                loaded: true,
                error: "Unable to load resources. Please try again later",
                subtitle: ""
            });
        });
    }, [setResourcesState]);

    useEffect(() => {
        loadResources();
    }, [loadResources]);

    /* Interaction Functions */

    const doSort = (type, res) => {
        let sortedRes = JSON.parse(JSON.stringify(res));
        switch (type) {
            case UiConstants.RESOURCE_SORT_TYPE.AlphaAsc:
                sortedRes.sort((a, b) => {
                    let lowerA = a.title.toLowerCase();
                    let lowerB = b.title.toLowerCase();
                    return (lowerA < lowerB) ? -1 : (lowerA > lowerB) ? 1 : 0;
                })
                break;
            case UiConstants.RESOURCE_SORT_TYPE.AlphaDesc:
                sortedRes.sort((a, b) => {
                    let lowerA = a.title.toLowerCase();
                    let lowerB = b.title.toLowerCase();
                    return (lowerA > lowerB) ? -1 : (lowerA < lowerB) ? 1 : 0;
                })
                break;
            case UiConstants.RESOURCE_SORT_TYPE.DateAsc:
                sortedRes.sort((a, b) => {
                    return (a.updatedAt < b.updatedAt) ? -1 : (a.updatedAt > b.updatedAt) ? 1 : 0;
                })
                break;
            case UiConstants.RESOURCE_SORT_TYPE.DateDesc:
                sortedRes.sort((a, b) => {
                    return (a.updatedAt > b.updatedAt) ? -1 : (a.updatedAt < b.updatedAt) ? 1 : 0;
                })
                break;
            default:
                break;
        }
        return sortedRes;
    }

    const onTagClick = (value) => {
        let sort = resourcesState.sortSelected.value;
        let newResources = resourcesState.pageResources;
        switch (value) {
            case UiConstants.RESOURCE_TYPE.All:
                newResources = doSort(sort, resourcesState.resources);
                break;
            case UiConstants.RESOURCE_TYPE.Learn:
                newResources = doSort(sort, resourcesState.resources.filter(r => r.category === UiConstants.RESOURCE_TYPE.Learn));
                break;
            case UiConstants.RESOURCE_TYPE.News:
                newResources = doSort(sort, resourcesState.resources.filter(r => r.category === UiConstants.RESOURCE_TYPE.News));
                break;
            case UiConstants.RESOURCE_TYPE.HowTo:
                newResources = doSort(sort, resourcesState.resources.filter(r => r.category === UiConstants.RESOURCE_TYPE.HowTo));
                break;
            default:
                break;
        }
        const newState = {
            resources: resourcesState.resources,
            pageResources: newResources,
            totalPages: Math.ceil(newResources.length / UiConstants.RESOURCE_PAGE_SIZE),
            page: 0,
            paginationStart: 0,
            category: value,
            sortSelected: resourcesState.sortSelected,
            loaded: true,
            error: "",
            subtitle: UiConstants.RESOURCE_TYPE_SUBTITLES[value]
        };
        setResourcesState(newState);
    };

    const onSortChange = (option) => {
        const sort = option.value;
        const newResources = sort !== "" ? doSort(sort, resourcesState.pageResources) : resourcesState.pageResources;
        const newState = {
            resources: resourcesState.resources,
            pageResources: newResources,
            totalPages: Math.ceil(newResources.length / UiConstants.RESOURCE_PAGE_SIZE),
            page: 0,
            paginationStart: 0,
            category: resourcesState.category,
            sortSelected: option,
            loaded: true,
            error: "",
            subtitle: resourcesState.subtitle
        };
        setResourcesState(newState);
    };

    const onPageClick = (start, current) => {
        const newState = {
            resources: resourcesState.resources,
            pageResources: resourcesState.pageResources,
            totalPages: resourcesState.totalPages,
            page: current,
            paginationStart: start,
            category: resourcesState.category,
            sortSelected: resourcesState.sortSelected,
            loaded: true,
            error: "",
            subtitle: resourcesState.subtitle
        };
        setResourcesState(newState);
    }

    const onContactClick = () => {
        navigate("/contact");
    }

    const getPageStart = () => {
        return resourcesState.page * UiConstants.RESOURCE_PAGE_SIZE;
    }

    const getPageEnd = () => {
        let end = (resourcesState.page + 1) * UiConstants.RESOURCE_PAGE_SIZE - 1;
        return end >= resourcesState.pageResources.length ? resourcesState.pageResources.length - 1 : end;
    }

    const getPageText = () => {
        return `Showing <span class="font-vg-regular">${getPageStart() + 1}-${getPageEnd() + 1}</span> of <span class="font-vg-regular">${resourcesState.pageResources.length}</span> items`;
    };

    /* Trial Upgrade Modal Functions */

    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

    const [isTrial, setIsTrial] = useState(context.user && context.user.trialAccount ? context.user.trialAccount : false);

    const onTrialUpgradeClick = () => {
        setUpgradeModalOpen(true);
    }

    const onTrialUpgradeDone = () => {
        setIsTrial(false);
        setUpgradeModalOpen(false);
    }

    /* Data Refresh Function */

    const [dataRefresh, setDataRefresh] = useState(false);
    
    useEffect(() => {
        if (dataRefresh) {
            setIsTrial(context.user && context.user.trialAccount ? context.user.trialAccount : false);
            setDataRefresh(false);
        }
    }, [dataRefresh, context, setIsTrial, setDataRefresh]);

    /* Terms Agreement Functions */

    const [termsModalOpen, setTermsModalOpen] = useState(false);

    useEffect(() => {
        if (context.user && context.user.id) {
            if (context.user.agreeToTerms && context.user.agreeToTerms === true) {
                setTermsModalOpen(false);
            } else {
                setTermsModalOpen(true);
            }
        } else {
            setTermsModalOpen(false);
        }
    }, [context, setTermsModalOpen]);

    /* Matomo Tracking Code */

    useEffect(() => {
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push(['setDocumentTitle', 'Learning Resources - Logged In']);
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);
    
    return(
        <div className="w-full h-full flex">
            <KitSideNav page="Resources" onToggle={(value) => setNavExpanded(value)}/>
            <div className={UiUtils.classNames(containerClass, navExpanded ? GeneralConstants.EXPANDED_NAV_MARGIN : GeneralConstants.COLLAPSED_NAV_MARGIN)}>
                {isTrial ? (
                    <KitTopBar 
                        banner={GeneralConstants.TRIAL_BANNER}
                        onBannerClick={() => onTrialUpgradeClick()}
                    />
                ) : (
                    <KitTopBar onDataRefresh={() => setDataRefresh(true)}/>
                )}
                <div className="flex flex-col items-stretch gap-10 py-8 px-10 bg-white">
                    <div className="flex flex-col gap-4">
                        <h1 className="font-vg-medium text-[64px] text-black">Resources</h1>
                        {resourcesState.loaded === true && resourcesState.error === "" && (
                        <div className="flex flex-col items-stretch gap-2 w-full">
                            <div className="flex justify-between items-center">
                                <div className="flex flex-start items-center gap-3">
                                    {UiConstants.RESOURCES_FILTER_OPTIONS.map(rf => (
                                    <TagButton
                                        key={`tag-${rf.value.toLowerCase()}`}
                                        id={`tag-${rf.value.toLowerCase()}`}
                                        color={UiConstants.TagColor.GREEN}
                                        size="large"
                                        label={rf.label}
                                        value={rf.value}
                                        selected={resourcesState.category === rf.value}
                                        onClick={onTagClick}
                                    />
                                    ))}
                                </div>
                                <div className="w-40">
                                    <SelectDropdown
                                        options={UiConstants.RESOURCE_SORT_OPTIONS}
                                        selectedOption={resourcesState.sortSelected}
                                        onChange={onSortChange}
                                    />
                                </div>
                            </div>
                            <div className="font-vg-book text-base text-black">{resourcesState.subtitle}</div>
                        </div>
                        )}
                    </div>
                    {resourcesState.loaded === false && (
                    <div className="flex justify-center items-center max-w-content-narrow mx-auto min-h-[400px]">
                        <h4 className="font-vg-medium text-3xl text-black">Loading Resources...</h4>
                    </div>
                    )}
                    {resourcesState.loaded === true && resourcesState.error !== "" && (
                    <div className="flex justify-center items-center max-w-content-narrow mx-auto min-h-[400px]">
                        <h4 className="font-vg-medium text-3xl text-black">{resourcesState.error}</h4>
                    </div>
                    )}
                    {resourcesState.loaded === true && resourcesState.error === "" && (
                    <>
                        <div className="flex flex-col items-stretch gap-1.5 w-full">
                            <p className="font-vg-book text-xs text-grey" dangerouslySetInnerHTML={{__html: getPageText()}}/>
                            <div className="grid grid-cols-3 gap-x-6 gap-y-10 min-h-[400px]">
                                {resourcesState.pageResources.filter((_,index) => index >= getPageStart() && index <= getPageEnd()).map(res => (
                                    <ResourceBlock
                                        key={`res-block-${res.key}`}
                                        id={`res-block-${res.key}`}
                                        type={res.resourceType}
                                        tags={[{ text: res.category === "HowTo" ? "How-to" : res.category, color: "green" }]}
                                        title={res.title}
                                        text={res.lead}
                                        link={`/resources/${res.key}`}
                                        image={res.heroImage.smallUrl}
                                        imageAlt={res.heroImage.alt}
                                        readTime={res.duration}
                                        durationText={res.durationText}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="flex w-full justify-center">
                            <Pagination
                            pageCount={resourcesState.totalPages}
                            current={resourcesState.page}
                            windowStart={resourcesState.paginationStart}
                            windowSize={UiConstants.RESOURCE_PAGINATION_WINDOW_SIZE}
                            onPageClick={(start, current) => onPageClick(start, current)}
                            />
                        </div>
                        <div className="bg-grey03 bg-home-faq bg-right-bottom bg-contain bg-no-repeat">
                            <div className="flex flex-col items-stretch justify-center gap-12 px-16 py-20 border-box max-w-content mx-auto">
                            <h3 className="font-vg-medium text-black text-[38px] leading-120 text-center">Frequently asked questions</h3>
                            <div className="flex flex-col items-stretch gap-1">
                                <FaqSet/>
                            </div>
                            <div className="flex flex-col items-center gap-3">
                                <p className="font-vg-regular text-xl text-black">Looking for something different?</p>
                                <Button label="Get in contact" size="large" variant="outline" onClick={onContactClick}/>
                            </div>
                            </div>
                        </div>
                    </>
                    )}
                    <Footer/>
                </div>
            </div>   
            <TrialUpgradeModal
                open={upgradeModalOpen}
                onUpgrade={(_) => onTrialUpgradeDone()}
                onClose={() => setUpgradeModalOpen(false)}
            />
            <TermsAgreementModal
                open={termsModalOpen}
                onAgreement={() => setTermsModalOpen(false)}
            />
        </div>
    );
}