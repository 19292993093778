import { useState, useContext, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import UiUtils from '../../utils/UiUtils';
import * as GeneralConstants from '../../constants/GeneralConstants';
import * as UiConstants from '../../constants/UiConstants';
import * as EntityConstants from '../../constants/EntityConstants';
import * as UserConstants from '../../constants/UserConstants';
import { GlobalContext } from '../../context/GlobalContext';
import OrgService from '../../services/OrgService';

import KitSideNav from '../../components/KitSideNav';
import KitTopBar from '../../components/KitTopBar';
import OrgEntHeader from '../../components/OrgEntHeader';
import OrgEntPanelHeader from '../../components/OrgEntPanelHeader';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import SelectDropdown from '../../components/SelectDropdown';
import CustomReactDatePicker from '../../components/CustomReactDatePicker';
import TextArea from '../../components/TextArea';
import EntityTable from '../../components/EntityTable';
import TrialUpgradeModal from '../../components/TrialUpgradeModal';
import TermsAgreementModal from '../../components/TermsAgreementModal';
import Footer from '../../components/Footer';

export default function OrganisationPage() {
    const {context, setContextValues} = useContext(GlobalContext);
    
    const [navExpanded, setNavExpanded] = useState(context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true);

    /* Role Access Code */

    const roles = context.user && context.user.roles ? context.user.roles : [];
    const [userCanEdit, setUserCanEdit] = useState(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.ORGANISATION, UserConstants.AccessTypes.EDIT));
    const [userCanCreateEntity, setUserCanCreateEntity] = useState(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.ENTITY, UserConstants.AccessTypes.CREATE));

    const [formActive, setFormActive] = useState(false);

    const [entCreating, setEntCreating] = useState(false);
    const [progCreating, setProgCreating] = useState(false);
    const [projCreating, setProjCreating] = useState(false);

    const [entError, setEntError] = useState("");
    const [progError, setProgError] = useState("");

    const containerClass = "flex flex-col items-stretch h-screen shrink grow overflow-y-scroll";

    const navigate = useNavigate();

    const getFormValues = useCallback(() => {
        let values;
        if (context.organisation) {
            let acncOption = UiConstants.EMPTY_OPTION;
            if (context.organisation.acncRegistered) {
                const index = UiConstants.YES_NO_OPTIONS.findIndex(o => o.value === context.organisation.acncRegistered);
                if (index > -1) {
                acncOption = UiConstants.YES_NO_OPTIONS[index];
                }
            }
            values = {
                contactName: context.organisation.contactName ? context.organisation.contactName : "",
                contactEmail: context.organisation.contactEmail ? context.organisation.contactEmail : "",
                contactRole: context.organisation.contactRole ? context.organisation.contactRole : "",
                name: context.organisation.name ? context.organisation.name : "",
                abn: context.organisation.abn ? context.organisation.abn : "",
                prefTradingName: context.organisation.prefTradingName ? context.organisation.prefTradingName : "",
                acncRegisteredOption: acncOption,
                dateEstablished: context.organisation.dateEstablished ? context.organisation.dateEstablished : null,
                headOffice: context.organisation.headOffice ? context.organisation.headOffice : "",
                phoneNumber: context.organisation.phoneNumber ? context.organisation.phoneNumber : "",
                website: context.organisation.website ? context.organisation.website : "",
                description: context.organisation.description ? context.organisation.description : ""
            };
        } else {
            values = {
                contactName: "",
                contactEmail: "",
                contactRole: "",
                name: "",
                abn: "",
                prefTradingName: "",
                acncRegisteredOption: UiConstants.EMPTY_OPTION,
                dateEstablished: "",
                headOffice: "",
                phoneNumber: "",
                website: "",
                description: ""
            };
        }
        return values;
    }, [context]);


    const validationSchema = Yup.object().shape({
        contactEmail: Yup.string()
            .email('Please enter a valid email address')
    });
    const formOptions = { 
        resolver: yupResolver(validationSchema),
        defaultValues: getFormValues() 
    };

    const { 
        control, 
        handleSubmit,
        watch, 
        reset,
        setValue
    } = useForm(formOptions);

    /* Unit List Functions */

    const getEnterprises = (activeOnly) => {
        if (activeOnly) {
            return context.organisation && context.organisation.enterprises ? 
            context.organisation.enterprises.filter(e => e.status ? e.status !== EntityConstants.EntityStatus.ARCHIVED : true) :
            [];
        } else {
            return context.organisation && context.organisation.enterprises ? context.organisation.enterprises : [];
        }
    }

    const getProgProjects = (activeOnly) => {
        const programs = context.organisation && context.organisation.programs ? context.organisation.programs : [];
        const projects = context.organisation && context.organisation.projects ? context.organisation.projects : [];
        let progProjects = [...programs, ...projects];
        if (activeOnly) {
            return progProjects.filter(p => p.status ? p.status !== EntityConstants.EntityStatus.ARCHIVED : true);
        } else {
            return progProjects;
        }
    }

    /* Interaction Functions */

    function editClicked(e) {
        e.preventDefault();
        setFormActive(!formActive);
    }

    function setSelection(type, id, edit) {
        let selections = context.selections ? JSON.parse(JSON.stringify(context.selections)) : {};
        delete selections.enterprise;
        delete selections.program;
        delete selections.project;
        selections[type] = { id: id, edit: edit };
        selections.type = type;
        setContextValues([{ key: "selections", value: selections}]);
    }

    function socEntActionClicked(key, index, row) {
        setSelection(EntityConstants.EntityType.ENTERPRISE, row._id, false);
        switch (key) {
            case "details":
                navigate("/kit/enterprise");
                break;
            case "indicators":
                navigate("/kit/indicators");
                break;
            case "data":
                navigate("/kit/datamanagement");
                break;
            default:
                break;
        }
    }

    function addSocEntClicked(e) {
        setEntCreating(true);
        setEntError("");
        const orgIndex = context.organisation && context.organisation.enterprises ? context.organisation.enterprises.length + 1 : 1;
        const name = `Social Enterprise #${orgIndex}`;
        OrgService.createEnterprise({
            name: name
        })
        .then(response => {
            setEntCreating(false);
            let organisation = context.organisation ? JSON.parse(JSON.stringify(context.organisation)) : { enterprises: [] };
            let selections = context.selections ? JSON.parse(JSON.stringify(context.selections)) : {};
            organisation.enterprises.push(response.data.entity);
            selections.enterprise = { id : response.data.entity._id, edit : true };
            selections.type = EntityConstants.EntityType.ENTERPRISE;
            setContextValues([
                { key: "organisation", value: organisation },
                { key: "selections", value: selections }
            ]);
            navigate("/kit/enterprise");
        })
        .catch(err => {
            setEntCreating(false);
            setEntError("An error occurred creating your enterprise. Please try again later.");
        });
    }

    function progProjActionClicked(key, index, row) {
        setSelection(row.entityType, row._id, false);
        switch (key) {
            case "details":
                navigate("/kit/progproject");
                break;
            case "indicators":
                navigate("/kit/indicators");
                break;
            case "data":
                navigate("/kit/datamanagement");
                break;
            default:
                break;
        }
    }

    function addProgramClicked(e) {
        setProgCreating(true);
        setProgError("");
        const progIndex = context.organisation && context.organisation.programs ? context.organisation.programs.length + 1 : 1;
        const name = `Program #${progIndex}`;
        OrgService.createProgram({
            name: name
        })
        .then(response => {
            setProgCreating(false);
            let organisation = context.organisation ? JSON.parse(JSON.stringify(context.organisation)) : { programs: [] };
            let selections = context.selections ? JSON.parse(JSON.stringify(context.selections)) : {};
            organisation.programs.push(response.data.entity);
            selections.program = { id : response.data.entity._id, edit : true };
            selections.type = EntityConstants.EntityType.PROGRAM;
            setContextValues([
                { key: "organisation", value: organisation },
                { key: "selections", value: selections }
            ]);
            navigate("/kit/progproject");
        })
        .catch(err => {
            setProgCreating(false);
            setProgError("An error occurred creating your program. Please try again later.");
        });
    }

    function addProjectClicked(e) {
        setProjCreating(true)
        setProgError("");
        const projIndex = context.organisation && context.organisation.projects ? context.organisation.projects.length + 1 : 1;
        const name = `Project #${projIndex}`;
        OrgService.createProject({
            name: name
        })
        .then(response => {
            setProgCreating(false);
            let organisation = context.organisation ? JSON.parse(JSON.stringify(context.organisation)) : { projects: [] };
            let selections = context.selections ? JSON.parse(JSON.stringify(context.selections)) : {};
            organisation.projects.push(response.data.entity);
            selections.project = { id : response.data.entity._id, edit : true };
            selections.type = EntityConstants.EntityType.PROJECT;
            setContextValues([
                { key: "organisation", value: organisation },
                { key: "selections", value: selections }
            ]);
            navigate("/kit/progproject");
        })
        .catch(err => {
            setProjCreating(false);
            setProgError("An error occurred creating your project. Please try again later.");
        });
    }

    /* Form Submit Functions */

    const prepareSubmit = useCallback((data) => {
        return {
            contactName: data.contactName,
            contactEmail: data.contactEmail,
            contactRole: data.contactRole,
            name: data.name,
            abn: data.abn,
            prefTradingName: data.prefTradingName,
            acncRegistered: data.acncRegisteredOption.value,
            dateEstablished: data.dateEstablished,
            headOffice: data.headOffice,
            phoneNumber: data.phoneNumber,
            website: data.website,
            description: data.description
        }
    }, []);

    const updateContext = useCallback((details) => {
        let organisation = context.organisation ? JSON.parse(JSON.stringify(context)) : {};
        organisation.contactName = details.contactName;
        organisation.contactEmail = details.contactEmail;
        organisation.contactRole = details.contactRole;
        organisation.name = details.name;
        organisation.abn = details.abn;
        organisation.prefTradingName = details.prefTradingName;
        organisation.acncRegistered = details.acncRegistered;
        organisation.dateEstablished = details.dateEstablished;
        organisation.headOffice = details.headOffice;
        organisation.phoneNumber = details.phoneNumber;
        organisation.website = details.website;
        organisation.description = details.description;
        setContextValues([{ key: 'organisation', value: organisation }]);
    }, [context, setContextValues]);

    const onSubmit = useCallback((data) => {
        const details = prepareSubmit(data);
        updateContext(details);
        OrgService.updateOrgData(details)
        .then(response => {
            //console.log(response);
        })
        .catch(error => {
            console.log(error);
        });
    },[prepareSubmit, updateContext]);

    /* Update Checking Functions */

    const checkFieldUpdate = useCallback((field, data) => {
        if (context.organisation) {
            if (context.organisation.hasOwnProperty(field)) {
                if (context.organisation[field] !== data[field]) {
                    onSubmit(data);
                }
            } else {
                if (data[field] !== "") {
                    onSubmit(data);
                }
            }
        }
    }, [context, onSubmit]);

    const onOrgNameSubmit = (data) => {
        checkFieldUpdate("name", data);
    };
    const onPrefTradingNameSubmit = (data) => {
        checkFieldUpdate("prefTradingName", data);
    };

    const onHeadOfficeSubmit = (data) => {
        checkFieldUpdate("headOffice", data);
    };

    const onPhoneNumberSubmit = (data) => {
        checkFieldUpdate("phoneNumber", data);
    };

    const onWebsiteSubmit = (data) => {
        checkFieldUpdate("website", data);
    };

    const onDescriptionSubmit = (data) => {
        checkFieldUpdate("description", data);
    };

    useEffect(() => {
        const subscription = watch((data, { name, type }) => {
            if (name === "acncRegisteredOption" || name === "dateEstablished") {
                checkFieldUpdate(name, data);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, checkFieldUpdate]);

    /* Trial Upgrade Modal Functions */

    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

    const [isTrial, setIsTrial] = useState(context.user && context.user.trialAccount ? context.user.trialAccount : false);

    const onTrialUpgradeClick = () => {
        setUpgradeModalOpen(true);
    }

    const onTrialUpgradeDone = (details) => {
        setIsTrial(false);
        setValue("abn", details.abn);
        setValue("name", details.organisationName);
        setUpgradeModalOpen(false);
    }
    
    /* Data Refresh Function */

    const [dataRefresh, setDataRefresh] = useState(false);
    
    useEffect(() => {
        if (dataRefresh) {
            reset(getFormValues());
            const roles = context.user && context.user.roles ? context.user.roles : [];
            setUserCanEdit(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.ORGANISATION, UserConstants.AccessTypes.EDIT));
            setUserCanCreateEntity(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.ENTITY, UserConstants.AccessTypes.CREATE));

            setIsTrial(context.user && context.user.trialAccount ? context.user.trialAccount : false);

            setDataRefresh(false);
        }
    }, [context, setUserCanEdit, setUserCanCreateEntity, dataRefresh, setIsTrial, setDataRefresh, getFormValues, reset]);

    /* Terms Agreement Functions */

    const [termsModalOpen, setTermsModalOpen] = useState(false);

    useEffect(() => {
    if (context.user && context.user.id) {
        if (context.user.agreeToTerms && context.user.agreeToTerms === true) {
            setTermsModalOpen(false);
        } else {
            setTermsModalOpen(true);
        }
    } else {
        setTermsModalOpen(false);
    }
    }, [context, setTermsModalOpen]);

    /* Matomo Tracking Code */

    useEffect(() => {
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push(['setDocumentTitle', 'Organisations']);
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);
    
    return(
        <div className="w-full h-full flex">
            <KitSideNav page="Organisation" onToggle={(value) => setNavExpanded(value)}/>
            <div className={UiUtils.classNames(containerClass, navExpanded ? GeneralConstants.EXPANDED_NAV_MARGIN : GeneralConstants.COLLAPSED_NAV_MARGIN)}>
                {isTrial ? (
                <KitTopBar 
                    onDataRefresh={() => setDataRefresh(true)} 
                    banner={GeneralConstants.TRIAL_BANNER}
                    onBannerClick={() => onTrialUpgradeClick()}
                />
                ) : (
                <KitTopBar onDataRefresh={() => setDataRefresh(true)}/>
                )}
                <div className="flex flex-col items-stretch gap-10 py-8 px-10 bg-white bg-org bg-contain bg-right-top bg-no-repeat">
                    <OrgEntHeader 
                        title="Organisation" 
                        subtitle={userCanEdit ? "View or edit your organisation profile, and view your reporting units" : "View your organisation profile and reporting units"}
                        showStrap={userCanEdit}
                        editing={formActive}
                        subTooltip="In Seedkit, reporting units are social enterprises, programs and projects for which you want to create indicators to track and measure."
                    />

                    <form className="flex flex-col items-stretch gap-10">
                        <div className="flex justify-end">
                            {userCanEdit && (
                            <Button 
                                variant="solid"
                                size="small" 
                                label={formActive ? UiConstants.DONE_BUTTON_LABEL : UiConstants.EDIT_BUTTON_LABEL}
                                leftIcon="PencilAltIcon" 
                                onClick={editClicked}
                            />
                            )}
                        </div>
                        <div className="grid grid-cols-12 gap-6 pt-10">
                            <OrgEntPanelHeader title="Organisation details" subtitle="" className="col-span-5"/>
                            <div className="col-span-7 flex flex-col items-stretch gap-8">
                                <Controller
                                    name="abn"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput label="ABN" variant="disabled" {...field}/>
                                    )}
                                />
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput
                                            ref={ref} 
                                            label="Organisation Name" 
                                            variant="disabled"
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onOrgNameSubmit)}  
                                        />
                                    )}
                                />
                                <Controller
                                    name="prefTradingName"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput
                                            ref={ref}  
                                            label="Preferred Trading Name" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onPrefTradingNameSubmit)} 
                                        />
                                    )}
                                />
                                <Controller
                                    name="acncRegisteredOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Registered with ACNC?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                )}
                                />
                                <Controller
                                    name="dateEstablished"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <CustomReactDatePicker 
                                            label="Date Established" 
                                            isDisabled={!formActive} 
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="headOffice"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput
                                            ref={ref}  
                                            label="Head Office" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onHeadOfficeSubmit)} 
                                        />
                                    )}
                                />
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput 
                                            ref={ref} 
                                            label="Phone Number" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onPhoneNumberSubmit)} 
                                        />
                                    )}
                                />
                                <Controller
                                    name="website"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput 
                                            ref={ref}
                                            label="Website" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onWebsiteSubmit)} 
                                        />
                                    )}
                                />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextArea
                                            ref={ref} 
                                            label="Please provide a brief (250 characters) description of your organisation's social purpose" 
                                            variant={formActive ? "default" : "disabled"}
                                            rows={4} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onDescriptionSubmit)} 
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            {userCanEdit && (
                            <Button 
                                variant="solid"
                                size="small" 
                                label={formActive ? UiConstants.DONE_BUTTON_LABEL : UiConstants.EDIT_BUTTON_LABEL} 
                                leftIcon="PencilAltIcon" 
                                onClick={editClicked}
                            />
                            )}
                        </div>
                    </form>

                    <div className="flex flex-col item-stretch gap-6">
                        <div className="flex flex-col gap-2">
                            <h4 className="font-vg-medium text-3.5xl text-black">Social Enterprises</h4>
                            {userCanEdit ? (
                            <p className="font-vg-book text-base text-grey">
                                Click on <span className="font-vg-regular">Details</span> link to view or edit enterprise details
                                or <span className="font-vg-regular">Indicators</span> link to view indicators,
                                or <span className="font-vg-regular">Data</span> link to manage indicator data.
                            </p>
                            ) : (
                            <p className="font-vg-book text-base text-grey">
                                Click on <span className="font-vg-regular">Details</span> link to view enterprise details
                                or <span className="font-vg-regular">Indicators</span> link to view indicators,
                                or <span className="font-vg-regular">Data</span> link to view indicator data.
                            </p>
                            )}
                        </div>
                        <EntityTable
                            columns={["name"]}
                            data={getEnterprises(false)}
                            variant="striped"
                            hideHeaders="true"
                            border="true"
                            scrolling="false"
                            actions={UiConstants.ORG_UNIT_ACTIONS}
                            onActionClick={socEntActionClicked}
                            noDataMessage="This organisation has no active social enterprises"
                        />
                        <div className="flex justify-between items-center">
                            <p className="font-vg-regular text-base text-red">{entError}</p>
                            <div className="flex items-center">
                                {userCanCreateEntity && (
                                <Button 
                                size="large" 
                                variant="outline"
                                label="Add Social Enterprise" 
                                className={entCreating ? "hidden" : ""} 
                                onClick={addSocEntClicked}
                                />
                                )}
                                <p className={entCreating ? "font-vg-regular text-base text-black" : "hidden"}>Creating enterprise...</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col item-stretch gap-6">
                        <div className="flex flex-col gap-2">
                            <h4 className="font-vg-medium text-3.5xl text-black">Programs / Projects</h4>
                            {userCanEdit ? (
                            <p className="font-vg-book text-base text-grey">
                                Click on <span className="font-vg-regular">Details</span> link to view or edit program or project details,
                                or <span className="font-vg-regular">Indicators</span> link to view indicators,
                                or <span className="font-vg-regular">Data</span> link to manage indicator data.
                            </p>
                            ) : (
                                <p className="font-vg-book text-base text-grey">
                                Click on <span className="font-vg-regular">Details</span> link to view program or project details,
                                or <span className="font-vg-regular">Indicators</span> link to view indicators,
                                or <span className="font-vg-regular">Data</span> link to view indicator data.
                            </p>
                            )}
                        </div>
                        <EntityTable
                            columns={["name"]}
                            data={getProgProjects(false)}
                            variant="striped"
                            hideHeaders="true"
                            border="true"
                            scrolling="false"
                            actions={UiConstants.ORG_UNIT_ACTIONS}
                            onActionClick={progProjActionClicked}
                            noDataMessage="This organisation has no active programs or projects"
                        />
                        <div className="flex justify-between items-center">
                            <p className="font-vg-regular text-base text-red">{progError}</p>
                            <div className="flex items-center gap-3">
                                {userCanCreateEntity && (
                                <>
                                <Button 
                                        size="large" 
                                        variant="outline"
                                        label="Add Program"
                                        className={projCreating || progCreating ? "hidden" : ""} 
                                        onClick={addProgramClicked}
                                />
                                <Button 
                                        size="large" 
                                        variant="outline"
                                        label="Add Project"
                                        className={projCreating || progCreating ? "hidden" : ""}  
                                        onClick={addProjectClicked}
                                />
                                </>
                                )}
                                <p 
                                className={projCreating || progCreating ? "font-vg-regular text-black" : "hidden"}
                                >
                                {progCreating ? "Creating program..." : (projCreating ? "Creating project..." : "")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
            <TrialUpgradeModal
                open={upgradeModalOpen}
                onUpgrade={(details) => onTrialUpgradeDone(details)}
                onClose={() => setUpgradeModalOpen(false)}
            />
            <TermsAgreementModal
                open={termsModalOpen}
                onAgreement={() => setTermsModalOpen(false)}
            />
        </div>
    );
}